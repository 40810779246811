import React from "react";
import '@fortawesome/free-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEthereum, faDocker } from '@fortawesome/free-brands-svg-icons';
import { faHouseFloodWater, faSatellite } from '@fortawesome/free-solid-svg-icons';
import Chip from '@mui/material/Chip';
import '../assets/styles/Expertise.scss';

const labelsBackend = [
    "Go",
    "Solidity",
    "Rust",
    "C++",
    "Linux",
    "Hardhat",
    "Remix",
    "Docker",
];

const labelsSEM = [
    "Copernicus EMS",
    "ICSMD",
    "GDACS",
    "GTS",
    "UNOSAT",
];

const labelsML = [
    "Sentinel-Hub",
    "PyTorch",
    "CUDA",
    "Scikit",
    "Scipy",
    "Hugging Face",
    "Matplotlib",
];

const labelsFrontend = [
    "Ethers.js",
    "Web3.js",
    "Metamask",

    "React",
    "NodeJS",
    "NPM",
    "Nginx",

    "APIs",
    "SQL Databases",
    
    // "FastAPI",
    // "Adminer",
    // "Grafana",
    // "MySQL",
    // "SQLite",
    // "PostgreSQL",
    // "MariaDB",
    // "MongoDB",
];

function Expertise() {
    return (
    <div className="container" id="expertise">
        <div className="skills-container">
            <h1>Expertise</h1>
            <div className="skills-grid">
                <div className="skill">
                    <FontAwesomeIcon icon={faEthereum} size="3x"/>
                    <h3>Distributed Ledger Technologies</h3>
                    <p>Development of Distributed Ledger Technology (DLT) and other Web3 systems from the architecture itself to Smart Contracts executed upon blockchains.</p>
                    <div className="flex-chips">
                        {/* <span className="chip-title">Tech stack:</span> */}
                        {labelsBackend.map((label, index) => (
                            <Chip key={index} className='chip' label={label} />
                        ))}
                    </div>
                </div>

                <div className="skill">
                    {/* <FontAwesomeIcon icon="fa-solid fa-house-flood-water" /> */}
                    <FontAwesomeIcon icon={faHouseFloodWater} size="3x"/>
                    <h3>Satellite-based Emergency Mapping</h3>
                    <p>Researching and optimising the current Satellite-based Emergency Mapping protocols to provide Earth Observation information faster to those who need it. Looking at a range of natural disasters from Earthquakes and Tropical Cyclones to Flooding and Droughts in the Copernicus and ICSMD protocols.</p>
                    <div className="flex-chips">
                        {/* <span className="chip-title">Tech stack:</span> */}
                        {labelsSEM.map((label, index) => (
                            <Chip key={index} className='chip' label={label} />
                        ))}
                    </div>
                </div>

                <div className="skill">
                    <FontAwesomeIcon icon={faSatellite} size="3x"/>
                    <h3>Earth Observation Machine Learning</h3>
                    <p>Automating Earth Observation analytics for Natural Disaster Response and Agricultural predictions. From classification and segmentation tasks to knowledge extrapolation using LLMs.</p>
                    <div className="flex-chips">
                        {/* <span className="chip-title">Tech stack:</span> */}
                        {labelsML.map((label, index) => (
                            <Chip key={index} className='chip' label={label} />
                        ))}
                    </div>
                </div>

                <div className="skill">
                    <FontAwesomeIcon icon={faDocker} size="3x"/>
                    <h3>Frontend Interfacing with Distributed Systems</h3>
                    <p>Once the DLT architecture is built, I build bridges between Web2 and Web3 to help regular users connect to the next generation of the internet securely and safely.</p>
                    <div className="flex-chips">
                        {/* <span className="chip-title">Tech stack:</span> */}
                        {labelsFrontend.map((label, index) => (
                            <Chip key={index} className='chip' label={label} />
                        ))}
                    </div>
                </div>

            </div>
        </div>
    </div>
    );
}

export default Expertise;